import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const contact = () => {
    return (
        <Layout>
            <SEO title="Contact Us" description={"Please get in contact with us so we can help you"}/>
            <section className="contact-page">
                <article className="contact-form">
                    <h3>get in touch</h3>
                    <form action="https://formspree.io/YOUR_ID" method="POST">
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="name"
                                className="form-control"
                            />
                            <input
                                type="email"
                                placeholder="email"
                                name="email"
                                className="form-control"
                            />
                            <textarea
                                name="message"
                                rows="5"
                                placeholder="message"
                                className="form-control"
                            />
                        </div>
                        <button type="submit" className="submit-btn btn">
                            submit here
                        </button>
                    </form>
                </article>
            </section>
        </Layout>
    )
}

export default contact
